import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function Government() {
    var [government, setGovernment] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/industries/government.txt'
        }).then((res) => {
            setGovernment(res.data)
        })
    }, [])
    console.log(government)
    return (
        <>
            <main>
                <div id="government">
                    <h1 style={{ color: 'white' }}>Government</h1>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: '50px', textAlign: 'justify', fontSize: '25px' }}>
                            {
                                government && government.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {typeof item === 'string' ? (
                                                <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{item}</p>
                                            ) : (
                                                Object.entries(item).map(([key, value]) => {
                                                    return (
                                                        <>
                                                            {
                                                                typeof value === 'string' ? (
                                                                    <>
                                                                        <h3 style={{ color: 'white' }}>{key}</h3>
                                                                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{value}</p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <h3 style={{ color: 'white' }}>{key}</h3>
                                                                        {
                                                                            value && value.map((subitem) => {
                                                                                return (
                                                                                    <ul>
                                                                                        <li style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{subitem}</li>
                                                                                    </ul>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                })
                                            )}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Government
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

function Location() {
    var navigate = useNavigate();

    useEffect(() => {
        displayIndiaContent();
    }, []);

    function displayIndiaContent() {
        var btn1 = document.getElementById('btn1');
        var btn2 = document.getElementById('btn2');
        btn1.style.backgroundColor = 'white';
        btn1.style.color = 'black';
        btn2.style.backgroundColor = 'rgb(8, 131, 247)';
        btn2.style.color = 'white';
        navigate('/corporate/location/india');
    }

    function Indiabtn() {
        var btn1 = document.getElementById('btn1');
        var btn2 = document.getElementById('btn2');
        if (btn1.style.backgroundColor === "rgb(8, 131, 247)") {
            btn1.style.backgroundColor = 'white';
            btn1.style.color = 'black';
            btn2.style.backgroundColor = 'rgb(8, 131, 247)';
            btn2.style.color = 'white';
        }
        else {
            btn2.style.backgroundColor = 'white';
            btn2.style.color = 'black';
            btn1.style.backgroundColor = 'rgb(8, 131, 247)';
            btn1.style.color = 'white';
        }
        navigate('/corporate/location/india')
    }
    function Malaysiabtn() {
        var btn1 = document.getElementById('btn1');
        var btn2 = document.getElementById('btn2');
        if (btn1.style.backgroundColor === "rgb(8, 131, 247)") {
            btn1.style.backgroundColor = 'white';
            btn1.style.color = 'black';
            btn2.style.backgroundColor = 'rgb(8, 131, 247)';
            btn2.style.color = 'white';
        }
        else {
            btn2.style.backgroundColor = 'white';
            btn2.style.color = 'black';
            btn1.style.backgroundColor = 'rgb(8, 131, 247)';
            btn1.style.color = 'white';
        }
        navigate('/corporate/location/malaysia')
    }
    return (
        <>
            <main>
                <div id="location" style={{ padding: '50px' }}>
                    <h1 style={{ color: 'white', marginBottom: '50px' }}>Location</h1>
                    <div id="locationbtn">
                        <button id="btn1" class='btn' style={{ backgroundColor: 'rgb(8, 131, 247)', color: 'white' }} onClick={() => Indiabtn()}>India</button>
                        <button id="btn2" class='btn' style={{ backgroundColor: 'rgb(8, 131, 247)', color: 'white', }} onClick={() => Malaysiabtn()}>Malaysia</button>
                    </div>
                    <Outlet></Outlet>
                </div>
            </main>
        </>
    )
}
export default Location
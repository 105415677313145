import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function CoreValue() {
    var [corevalue, setCorevalue] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/corporate/corevalue.txt'
        }).then((res) => {
            setCorevalue(res.data)
        })
    }, [])
    console.log(corevalue)
    return (
        <>
            <main>
                <div id="corevalue">
                    <h1 style={{ color: 'white' }}>Core Value</h1>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '45px' }}>
                        <h3 style={{ color: 'white' }}>VENSYX Data Solutions Core Values</h3>
                        {
                            corevalue && corevalue.map((data) => {
                                return (
                                    <ul>
                                        {
                                            data.VENSYX.map((details) => {
                                                return <li style={{ color: 'white', textAlign: 'justify', fontFamily: 'Raleway, sans-serif' }}>{details}</li>
                                            })
                                        }
                                    </ul>
                                )
                            })
                        }
                    </div>
                </div>
            </main>
        </>
    )
}
export default CoreValue
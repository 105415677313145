import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function B2BMobileApps() {
    var [b2b, setB2b] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/enterpriseMobility/b2b_mobileApps.txt'
        }).then((res) => {
            setB2b(res.data)
        })
    }, [])
    console.log(b2b)
    return (
        <>
            <main>
                <div id="b2bmobile">
                    <h1 style={{ color: 'white' }}>B2B Mobile Apps Development</h1>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ padding: '50px', textAlign: 'justify', fontSize: '25px' }}>
                            {
                                b2b && b2b.map((item, index) => {
                                    if (typeof item === 'string') {
                                        return <p key={index} style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{item}</p>;
                                    } else if (Array.isArray(item)) {
                                        return (
                                            <ul key={index}>
                                                {item.map((subItem, subIndex) => (
                                                    <li key={subIndex} style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{subItem}</li>
                                                ))}
                                            </ul>
                                        );
                                    } else {
                                        return (
                                            <div key={index}>
                                                {Object.keys(item).map((key) => (
                                                    <div key={key}>
                                                        <h2 style={{ color: 'white' }}>{key}</h2>
                                                        <ul>
                                                            {item[key].map((subItem, subIndex) => (
                                                                <p key={subIndex} style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{subItem}</p>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default B2BMobileApps
import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function SocialResponsibility() {
    var [socialresponsibility, setSocialresponsibility] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/corporate/socialResponsibility.txt'
        }).then((res) => {
            setSocialresponsibility(res.data)
        })
    }, [])
    console.log(socialresponsibility)
    return (
        <>
            <main>
                <div id="socialresponsibility">
                    <h1 style={{ color: 'white' }}>Social Responsibility</h1>
                    <div id="socialresponsibilityblock">
                        <div id="socialresponsibilityimg">
                            <img src="/images/social.jpg" alt="" />
                        </div>
                        <div id="socialresponsibilitycontent">
                            {
                                socialresponsibility && socialresponsibility.map((data) => {
                                    return (
                                        <>
                                            {Array.isArray(data) ? (
                                                <div>
                                                    <h2 style={{ color: 'white' }}>Environment</h2>
                                                    {data.map((subItem, subIndex) => (
                                                        <p key={subIndex} style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{subItem}</p>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{data}</p>
                                            )}
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default SocialResponsibility
import React from "react";
import { Outlet } from "react-router-dom";

function Industries() {
    return (
        <div style={{ marginTop: '75px', backgroundColor: 'rgb(5, 5, 39)', height: 'auto' }}>
            <div id='socialMedia'>
                <a href="tel: +91 9000316721" target="_blank">
                    <i class="bi bi-telephone">
                        <span class="tooltiptext">+91-9000316721</span>
                    </i>
                </a>
                <a href="mailto:info@vensyx.com?subject=subject text" target="_blank">
                    <i class="bi bi-envelope-at">
                        <span class="tooltiptext">info@vensyx.com</span>
                    </i>
                </a>
                <a href="https://www.linkedin.com/company/vensyx-data-solutions-pvt-ltd-/about/" target="_blank">
                    <i class="bi bi-linkedin">
                        <span class="tooltiptext">https://www.linkedin.com/vensyx</span>
                    </i>
                </a>
                {/* <a href="" target="_blank">
                        <i class="bi bi-facebook">
                            <span class="tooltiptext">https://www.facebook.com/vensyx</span>
                        </i>
                    </a> */}
                <a href="https://twitter.com/vensyx2014" target="_blank">
                    <i class="bi bi-twitter">
                        <span class="tooltiptext">https://twitter.com/vensyx2014</span>
                    </i>
                </a>
                <a href="https://wa.me/+919000316721" target="_blank">
                    <i class="bi bi-whatsapp">
                        <span class="tooltiptext">+91-9000316721</span>
                    </i>
                </a>
            </div>
            <div id="makeanenquiry">
                <a href="/form">
                    <span id="make" style={{ width: '40px', height: '100px', padding: '12px', backgroundColor: 'rgb(230, 16, 165)', color: 'white' }}>Make An Enquiry</span>
                </a>
            </div>
            <Outlet></Outlet>
            <footer>
                <div id="footerblock">
                    <div id="footercontent1">
                        <div id="footercontentblock1">
                            <div id="footer1">
                                <h3 style={{ color: 'white' }}>Solutions</h3>
                                <div class='links'>
                                    <ul>
                                        <li><a href="/solutions/bigdata">Big Data & Advanced Analytics</a></li>
                                        <li><a href="/solutions/businessIntelligence">Business Intelligence solutions</a></li>
                                        <li><a href="/solutions/datamanagement">Data Management</a></li>
                                        <li><a href="/solutions/enterprisedatawarehouse">Enterprise Data Warehouse</a></li>
                                        <li><a href="/solutions/applicationmiddleware">Application Middleware Management</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div id="divide1"></div>
                            <div id="footer2">
                                <h3 style={{ color: 'white' }}>Services</h3>
                                <div class='links'>
                                    <ul>
                                        <li><a href="/services/enterprisemobility">Enterprise Mobility Service</a></li>
                                        <li><a href="/services/mobileapps">Mobile Apps Development</a></li>
                                        <li><a href="/services/businessintelligenceservices">Business Intelligence Services</a></li>
                                        <li><a href="/services/webapplication">Web Application Development</a></li>
                                        <li><a href="/services/testing">Testing and QA Services</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="divide2"></div>
                        <div id="footercontentblock2">
                            <div id="footer3">
                                <h3 style={{ color: 'white' }}>Industries</h3>
                                <div class='links'>
                                    <ul>
                                        <li><a href="/industries/telecommunications">Telecommunications</a></li>
                                        <li><a href="/industries/retail">Retail</a></li>
                                        <li><a href="/industries/financialservices">Financial Services</a></li>
                                        <li><a href="/industries/manufacturing">Manufacturing</a></li>
                                        <li><a href="/industries/utilities">Utilities</a></li>
                                        <li><a href="/industries/government">Government</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div id="divide3"></div>
                            <div id="footer4">
                                <h3 style={{ color: 'white' }}>Company</h3>
                                <div class='links'>
                                    <ul>
                                        <li><a href="/corporate/aboutus">About Us</a></li>
                                        <li><a href="/corporate/vissionmission">Vission & Mission</a></li>
                                        <li><a href="/corporate/corevalue">Core Value</a></li>
                                        <li><a href="/corporate/socialresponsibility">Social Responsibility</a></li>
                                        <li><a href="/corporate/location">Location</a></li>
                                        <li><a href="/corporate/teams">Team</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="divide4"></div>
                    <div id="footertext">
                        <span>
                            <a href="/termsofuse" style={{ marginRight: '10px' }}>Terms of Use</a>
                            <a href="/privacypolicy">Privacy Policy</a>
                        </span>
                        <p style={{ color: 'white' }}>Copyright © 2014 - VENSYX Data Solutions</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Industries
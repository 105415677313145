import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function OpenSource() {
    var [opensource, setOpensource] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/webApplication/open_source.txt'
        }).then((res) => {
            setOpensource(res.data)
        })
    }, [])
    console.log(opensource)
    return (
        <>
            <main>
                <div id="opensource">
                    <h1 style={{ color: 'white' }}>Open Source Development</h1>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ padding: '50px', textAlign: 'justify', fontSize: '25px' }}>
                            {
                                opensource && opensource.map((data) => {
                                    return (
                                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{data}</p>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default OpenSource
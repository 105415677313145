import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function AboutUs() {
    var [aboutus, setAboutus] = useState();
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/corporate/about.txt'
        }).then((res) => {
            setAboutus(res.data)
        })
    }, [])
    console.log(aboutus)
    return (
        <div>
            <main>
                <div id="aboutus">
                    <h1 style={{ color: 'white' }}>About Us</h1>
                    <div id="aboutusblock">
                        <div id="aboutuscontent">
                            {
                                aboutus && aboutus.map((data) => {
                                    return (
                                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{data}</p>
                                    )
                                })
                            }
                        </div>
                        <div id="aboutusimage">
                            <img src="/images/about1.jpg" alt="aboutus"/>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default AboutUs
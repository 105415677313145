import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function Retail() {
    var [retail, setRetail] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/industries/retail.txt'
        }).then((res) => {
            setRetail(res.data)
        })
    }, [])
    console.log(retail)
    return (
        <>
            <main>
                <div id="retail">
                    <h1 style={{ color: 'white' }}>Retail</h1>
                    <div id="retailblock">
                        <div id="img">
                            <img src="/images/retail1.jpg" alt="retail"/>
                        </div>
                        <div id="retailcontent">
                            {
                                retail && retail.map((data) => {
                                    return (
                                        <>
                                            {Array.isArray(data) ? (
                                                <div>
                                                    <ul>
                                                        {data.map((subItem, subIndex) => (
                                                            <li key={subIndex} style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{subItem}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : (
                                                <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{data}</p>
                                            )}
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Retail
import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function LogMode() {

    return (
        <>
            <main>
                <div id="logmode">
                    <h1 style={{ color: 'white' }}>Log-Mode</h1>
                    <div id="logmodeblock">
                        <h4 style={{ color: 'white' }}>Logistic Movement optimization Delivery Engagement (Log-MODE)</h4>
                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>Log-MODE is a universal program designed to control all stages of delivery and field services operations. The service allows to perform a wide range of tasks in logistics and delivery – transportation arrangements, transport processes optimization and cost saving.</p>
                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>
                            Log-MODE comprises a web-version for dispatchers and a mobile application for driver.  Web-version ensures effective operating procedures to create trips, order placement, planning and distribution, Google Maps-based routing, delivery process monitoring and coordination. Log-MODE mobile app is designed for drivers to easily manage to deliver products to specified locations.
                        </p>
                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>
                            Log-MODE used bigdata platform to create delivery schedule dynamic, based on the inelegancy options on urgent need basis, low fright charges, return fleet and availability of truck and driver timings.
                        </p>
                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>
                            Log-MODE have following features.
                        </p>
                        <h4 style={{ color: 'white' }}>Real-time tracking</h4>
                        <h5 style={{ color: 'white' }}>
                            Driver can see his delivery location and Route map in his mobile
                        </h5>
                        <h5 style={{ color: 'white' }}>
                            Manager Can see all movements in his Dashboard
                        </h5>
                        <img src="/images/scr1.png" alt="" width="600px" height="300px" />
                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>
                            <b>Manager can change the schedule trips based on circumstances.</b>
                        </p>
                        <img src="/images/scr2.png" alt="" width="600px" height="300px" />
                        <h4 style={{ color: 'white' }}>
                            Request & Approvals
                        </h4>
                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>
                            Approval process was implemented for the requests made by drivers like fuel, delivery problems, vehicle problems.
                        </p>
                        <img src="/images/scr3.png" alt="" width="600px" height="300px" />
                    </div>
                </div>
            </main>
        </>
    )
}
export default LogMode
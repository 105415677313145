import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function VissionMission() {
    var [visionmission, setVisionmission] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/corporate/vision_mission.txt'
        }).then((res) => {
            setVisionmission(res.data)
        })
    }, [])
    console.log(visionmission)
    return (
        <>
            <main>
                <div id="visionandmission">
                    <h1 style={{ color: 'white' }}>Vision & Mission</h1>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '60%', padding: '50px', textAlign: 'justify', fontSize: '25px' }}>
                            <h4 style={{ color: 'white' }}>Vision</h4>
                            {
                                visionmission && visionmission.map((data) => {
                                    return (
                                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{data.vision}</p>
                                    )
                                })
                            }
                        </div>
                        <div style={{ width: '40%', paddingRight: '50px', paddingTop: '50px' }}>
                            <img src="/images/vision1.jpg" alt="" width="100%" height="300px" style={{ borderRadius: '10px' }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '50px' }}>
                        <div style={{ width: '60%', padding: '50px', textAlign: 'justify', fontSize: '25px' }}>
                            <h4 style={{ color: 'white' }}>Mission</h4>
                            {
                                visionmission && visionmission.map((data) => {
                                    return (
                                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{data.mission}</p>
                                    )
                                })
                            }
                        </div>
                        <div style={{ width: '40%', paddingRight: '50px', paddingTop: '50px' }}>
                            <img src="/images/mission2.jpg" alt="" width="100%" height="300px" style={{ borderRadius: '10px' }} />
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default VissionMission
import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function QTP() {
    var [qtp, setQtp] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/testing/qtp.txt'
        }).then((res) => {
            setQtp(res.data)
        })
    }, [])
    console.log(qtp)
    return (
        <>
            <main>
                <div id="qtp">
                    <h1 style={{ color: 'white' }}>QTP</h1>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ padding: '50px', textAlign: 'justify', fontSize: '25px' }}>
                            {
                                qtp && qtp.map((item, index) => {
                                    if (typeof item === 'string') {
                                        return <p key={index} style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{item}</p>;
                                    } else {
                                        const key = Object.keys(item)[0];
                                        const value = item[key];
                                        if (Array.isArray(value)) {
                                            return (
                                                <div key={index}>
                                                    <h3 style={{ color: 'white' }}>{key}</h3>
                                                    <ul>
                                                        {value.map((subItem, subIndex) => (
                                                            <li key={subIndex} style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{subItem}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={index}>
                                                    <h3 style={{ color: 'white' }}>{key}</h3>
                                                    <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{value}</p>
                                                </div>
                                            );
                                        }
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default QTP
import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function FinancialServices() {
    var [financial, setFinancial] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/industries/financial_services.txt'
        }).then((res) => {
            setFinancial(res.data)
        })
    }, [])
    console.log(financial)
    return (
        <>
            <main>
                <div id="financialservice">
                    <h1 style={{ color: 'white' }}>Financial Services</h1>
                    <div id="financialserviceblock">
                        <div id="financialservicecontent">
                            {
                                financial && financial.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {typeof item === 'string' ? (
                                                <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{item}</p>
                                            ) : (
                                                Object.entries(item).map(([key, value]) => {
                                                    return (
                                                        <>
                                                            {
                                                                typeof value === 'string' ? (
                                                                    <>
                                                                        <h4 style={{ color: 'white' }}>{key}</h4>
                                                                        <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{value}</p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <h4 style={{ color: 'white' }}>{key}</h4>
                                                                        {
                                                                            value && value.map((subitem) => {
                                                                                return (
                                                                                    <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{subitem}</p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                })
                                            )}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div id="financialserviceimg">
                            <img src="/images/financial.jpg" alt="financial" style={{ borderRadius: '10px' }}/>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default FinancialServices
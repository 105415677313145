import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function EnterpriseIOSApp() {
    var [enterpriseios, setEnterpriseios] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/enterpriseMobility/enterprise_iosApp.txt'
        }).then((res) => {
            setEnterpriseios(res.data)
        })
    }, [])
    console.log(enterpriseios)
    return (
        <>
            <main>
                <div id="enterpriseiosapp">
                    <h1 style={{ color: 'white' }}>Enterprise IOS App Development</h1>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ padding: '50px', textAlign: 'justify', fontSize: '25px' }}>
                            {
                                enterpriseios && enterpriseios.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {typeof item === 'string' ? (
                                                <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{item}</p>
                                            ) : (
                                                Object.entries(item).map(([key, value]) => {
                                                    return (
                                                        <>
                                                            <h3 style={{ color: 'white' }}>{key}</h3>
                                                            <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{value}</p>
                                                        </>
                                                    )
                                                })
                                            )}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default EnterpriseIOSApp
import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';


function ApplicationMiddleware() {
    var [application, setApplication] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/solutions/application_middleware.txt'
        }).then((res) => {
            setApplication(res.data)
        })
    }, [])
    console.log(application)
    return (
        <>
            <main>
                <div id="applicationmiddleware">
                    <h1 style={{ color: 'white' }}>Application Middleware Management</h1>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ padding: '50px', textAlign: 'justify', fontSize: '25px' }}>
                            {
                                application && application.map((item, index) => (
                                    <div key={index}>
                                        {
                                            typeof item === 'string' ? (
                                                <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{item}</p>
                                            ) : (
                                                <div>
                                                    {
                                                        Object.entries(item).map(([title, content], idx) => (
                                                            <div key={idx}>
                                                                <h3 style={{ color: 'white' }}>{title}</h3>
                                                                <ul>
                                                                    {
                                                                        content.map((point, i) => (
                                                                            <li key={i} style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{point}</li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default ApplicationMiddleware
import React from "react";
import axios from 'axios';
import { useEffect, useState } from 'react';

function Manufacturing() {
    var [manufacturing, setManufacturing] = useState()
    useEffect(() => {
        axios({
            method: 'GET',
            url: '/files/industries/manufacturing.txt'
        }).then((res) => {
            setManufacturing(res.data)
        })
    }, [])
    console.log(manufacturing)
    return (
        <>
            <main>
                <div id="manufacturing">
                    <h1 style={{ color: 'white' }}>Manufacturing</h1>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: '50px', textAlign: 'justify', fontSize: '25px' }}>
                            {
                                manufacturing && manufacturing.map((data) => {
                                    return (
                                        <>
                                            {Array.isArray(data) ? (
                                                <div>
                                                    <ul>
                                                        {data.map((subItem, subIndex) => (
                                                            <li key={subIndex} style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{subItem}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : (
                                                <p style={{ color: 'white', fontFamily: 'Raleway, sans-serif' }}>{data}</p>
                                            )}
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Manufacturing